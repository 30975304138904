<!--
 * @Author: Liu Hui
 * @Description: 客户标准销售价
-->
<template>
  <scroll-layout>
    <template slot="header">
      <div v-if="pageButtons.length > 0" class="button-wrap">
        <template v-for="(button, index) in pageButtons">
          <ykc-button
            :key="index"
            v-if="button.enabled"
            v-bind="button"
            @click="button.handleClick(button, $event)">
            {{ button.text }}
          </ykc-button>
        </template>
      </div>
    </template>
    <div class="fleet-match-content">
      <YkcDetailPlus class="box-card">
        <div slot="header">
          <span>基础资料</span>
        </div>
        <div class="baseInfo">
          <div class="header">
            <div class="head">
              <img :src="detail.imageUrl" alt="" class="" />
              <div>Id:{{ dealData(detail.id) }}</div>
              <!-- <div class="vip"></div> -->
            </div>
          </div>
          <div class="content">
            <div class="top">
              <div>
                <span class="label">账号:</span>
                <span class="value">
                  {{ dealData(detail.userAccount) }}
                </span>
              </div>
              <div>
                <span class="label">手机号:</span>
                <span class="value">
                  {{ dealData(detail.userPhone) }}
                </span>
              </div>
              <div>
                <span class="label">注册时间:</span>
                <span class="value">
                  {{ dealData(detail.createdTime) }}
                </span>
              </div>
              <div>
                <span class="label">姓名:</span>
                <span class="value">
                  {{ dealData(detail.nickName) }}
                </span>
              </div>
              <div>
                <span class="label">城市:</span>
                <span class="value">
                  {{ dealData(detail.provinceId) }}
                </span>
              </div>
              <div>
                <span class="label">最近登录时间:</span>
                <span class="value">
                  {{ dealData(detail.lastLoginTime) }}
                </span>
              </div>
              <div>
                <span class="label">实体卡卡号:</span>
                <span class="value">
                  {{ dealData(detail.availableCardCount) }}
                </span>
              </div>
              <div>
                <span class="label">注册渠道:</span>
                <span class="value">
                  {{ dealData(detail.registerType) }}
                </span>
              </div>
              <div>
                <span class="label">最近充电时间:</span>
                <span class="value">
                  {{ dealData(detail.lastLoginTime) }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </YkcDetailPlus>
      <div
        style="
          display: flex;
          width: 100%;
          height: 500px;
          flex: 1;
          flex-direction: row;
          justify-content: space-between;
        ">
        <YkcDetailPlus class="box-card-prop-left" :labelWidth="150">
          <div slot="header" class="clearfix">
            <span>会员信息</span>
          </div>
          <div class="customerProperty">
            <div class="item-left">
              <span class="label">归属机构:</span>
              <span class="value">
                {{ dealData(detail.belongToName) }}
              </span>
            </div>
            <div class="item-left">
              <span class="label">启充金额:</span>
              <span class="value">
                {{ dealData(detail.startLimitAmount) }}
              </span>
            </div>
            <div class="item-left">
              <span class="label">停充金额:</span>
              <span class="value">
                {{ dealData(detail.stopLimitAmount) }}
              </span>
            </div>
            <div class="item-left">
              <span class="label">一号多充:</span>
              <span class="value">
                {{ dealData(detail.multipleChargeType) == 0 ? '关闭' : '开启' }}
              </span>
            </div>
          </div>
        </YkcDetailPlus>
        <YkcDetailPlus class="box-card-prop-right" :labelWidth="150">
          <div slot="header" class="clearfix">
            <span>绑定车辆</span>
          </div>
          <ykc-table
            ref="YkcTable"
            style="height: 200px"
            :data="tableData"
            :columns="tableColumns"
            operateFixedType="right">
            <ykc-pagination
              slot="pagination"
              ref="YkcPagination"
              direct="flex-end"
              :total="pageInfo.total"
              :current-page.sync="pageInfo.pageIndex"
              @current-change="handlePaginationCurrentChange"
              :page-size.sync="pageInfo.pageSize"
              @size-change="handlePaginationSizeChange"></ykc-pagination>
          </ykc-table>
        </YkcDetailPlus>
      </div>
      <YkcDetailPlus class="box-card" :labelWidth="150">
        <div slot="header" class="clearfix">
          <span>账户信息</span>
        </div>
        <div class="userInfo">
          <div class="item">
            <div class="title">账户余额(元)</div>
            <div class="itembg bg4"></div>
            <span class="value">{{ detail.balance }}</span>
          </div>
          <div class="item">
            <div class="title">可用优惠券(元)</div>
            <div class="itembg bg5"></div>
            <span class="value">{{ detail.couponCount }}</span>
          </div>
          <div class="item">
            <div class="title">可用抵用卡(张 )</div>
            <div class="itembg bg6"></div>
            <span class="value">{{ detail.cardCount }}</span>
          </div>
        </div>
      </YkcDetailPlus>
    </div>
  </scroll-layout>
</template>

<script>
  import { customerUser } from '@/service/apis';
  import { getListNameById, dealData } from '../../../utils/index';

  export default {
    name: 'SellingPriceDetail',
    components: {},
    data() {
      return {
        id: '',
        dictionary: {},
        tableData: [],
        baseDiscountList: [],
        pageInfo: {
          pageIndex: 1,
          pageSize: 10,
        },
        tableColumns: [
          { label: '车牌号', prop: 'plateNumber', minWidth: '100px' },
          { label: 'VIN', prop: 'vinCode', minWidth: '100px' },
          { label: '车辆产权', prop: 'purpose', minWidth: '100px' },
        ],
        dictionaryList: {
          status: [
            { id: '0', name: '是' },
            { id: '1', name: '否' },
          ],
        },
        detail: {
          id: 10,
          userAccount: 'sc7',
          nickName: 'sc7',
          userPhone: '18522222223',
          provinceId: null,
          districtId: null,
          chargeCardNo: null,
          startLimitAmount: null,
          stopLimitAmount: null,
          multipleChargeType: null,
          userAvatar: null,
          registerType: null,
          userType: null,
          lastLoginTime: null,
          createdTime: null,
          belongToId: null,
          orgName: null,
          mainOrgId: null,
          status: '0',
          belongToName: 'sc1',
          mainOrgName: 'sc',
          balance: 3.0,
          level: null,
          levelName: null,
          points: null,
          availableCouponCount: null,
          availableCardCount: null,
          carInfos: null,
        },
      };
    },
    computed: {
      pageButtons() {
        return [
          {
            text: '返回',
            type: 'plain',
            enabled: true,
            handleClick: () => {
              this.$router.go(-1);
            },
          },
        ];
      },
    },
    created() {
      const { id } = this.$route.query;
      this.id = id;
      console.log('this.$route', this.$route);
      this.getDetail();
      this.$store
        .dispatch('getDictionaryByKeys', ['FLEET_TYPE', 'PREFERENTIAL_TYPE', 'CONFIGURE_ATTRIBUTE'])
        .then(res => {
          this.dictionary = res;
          console.log('this.dictionary---', this.dictionary);
        });
    },
    methods: {
      dealData,
      getListNameById,
      /**
       * @desc 分页 change 事件监听器
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.pageIndex = current;
        this.searchTableList();
      },
      /**
       * 分页 size  change  事件监听器
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.pageIndex = 1;
        this.pageInfo.pageSize = size;
        this.searchTableList();
      },
      /**
       * 获取详情数据
       */
      getDetail() {
        customerUser
          .queryCompanyUserPageList({ id: this.id })
          .then(data => {
            this.detail = data && data.records[0];
            // this.baseDiscountList = [this.details.baseDiscount] || [];
            console.log('this.details---', this.detail);
          })
          .catch(() => {});
      },
    },
  };
</script>
<style lang="scss" scoped>
  .fleet-match-content {
    padding: 10px;
    display: flex;
    flex-flow: column;
    .box-card-prop-right {
      width: 69.5%;
      height: 300px;
    }
    .box-card-prop-left {
      width: 30%;
      height: 300px;
      .customerProperty {
        .item-left {
          margin-top: 20px;
        }
        .label {
          color: #9b9b9b;
          font-size: 12px;
          margin-right: 10px;
        }
        .value {
          font-weight: 400;
          font-size: 12px;
        }
      }
    }
    .box-card {
      // width: 50%;
      margin: 5px 0;
      .baseInfo {
        display: flex;
        font-size: 14px;
        .header {
          width: 160px;
          display: flex;
          justify-content: center;
          .head {
            display: flex;
            flex-flow: column;
            justify-content: flex-start;
            img {
              width: 80px;
              height: 80px;
              border-radius: 80px;
            }
            .level {
              position: relative;
              background: #ffffff linear-gradient(136deg, rgba(255, 232, 0, 0.7) 0%, #feb124 100%);
              height: 17px;
              border-radius: 10px;
              top: -10px;
              color: #fff;
              text-align: right;
              padding-right: 6px;
              line-height: 17px;
              font-weight: bold;
              font-style: italic;
              font-size: 13px;
              &::before {
                position: absolute;
                bottom: 0px;
                width: 26px;
                height: 26px;
                content: ' ';
                left: -5px;
                bottom: -1px;
                background: url('../../../assets/images/vip.png') no-repeat;
                background-size: 100%;
              }
            }
          }
        }
        .content {
          flex-grow: 1;
          display: flex;
          flex-wrap: wrap;
          .label {
            color: #9b9b9b;
            font-size: 12px;
            margin-right: 10px;
          }
          .value {
            font-weight: 400;
            font-size: 12px;
          }
          .top {
            display: flex;
            flex-wrap: wrap;
            & > div {
              width: calc(100% / 3);
              height: 30px;
              line-height: 30px;
              display: flex;
              .label {
                color: #9b9b9b;
                font-size: 12px;
                margin-right: 10px;
              }
              .value {
                font-weight: 400;
                font-size: 12px;
              }
            }
          }
        }
      }
      .vipInfo {
        display: flex;
        .item {
          border: 1px solid #3773fc80;
          background-color: #3773fc1a;
        }
      }
      .userInfo {
        display: flex;
        .item {
          border: 1px solid #44d7b680;
          background-color: #44d7b61a;
        }
      }

      .item {
        position: relative;
        width: calc((100% - 20px) / 3);
        border-radius: 10px;
        height: 188px;
        margin-right: 20px;
        display: flex;
        flex-flow: column;
        justify-content: center;
        &:last-child {
          margin-right: 0px;
        }
        .title {
          position: absolute;
          width: 100%;
          font-size: 14px;
          font-weight: bold;
          top: 0;
          left: 0;
          padding: 20px;
        }
        .value {
          font-weight: 600;
          font-size: 40px;
          height: 56px;
          line-height: 56px;
        }
        .link {
          color: #4d8cfd;
          font-size: 12px;
          height: 17px;
          line-height: 17px;
        }
      }
      .itembg {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 72px;
        height: 72px;
        background-repeat: no-repeat;
        background-size: 100%;
        &.bg1 {
          background-image: url(../../../assets/images/itembg1.png);
        }
        &.bg2 {
          background-image: url(../../../assets/images/itembg2.png);
        }
        &.bg3 {
          background-image: url(../../../assets/images/itembg3.png);
        }
        &.bg4 {
          background-image: url(../../../assets/images/itembg4.png);
        }
        &.bg5 {
          background-image: url(../../../assets/images/itembg5.png);
        }
        &.bg6 {
          background-image: url(../../../assets/images/itembg6.png);
        }
      }
      .ellipsis {
        width: 500px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      table {
        width: 100%;
        border-left: 1px solid #ccc;
        border-top: 1px solid #ccc;
        th {
          height: 44px;
        }
        td {
          height: 40px;
        }
        th,
        td {
          border-bottom: 1px solid #ccc;
          border-right: 1px solid #ccc;
        }
      }
    }
  }
</style>
